var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"carrierValidation"},[_c('b-modal',{attrs:{"id":this.$attrs.id,"title":"Carrier details","size":"l","scrollable":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.formBusy,"variant":"secondary"},on:{"click":close}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"disabled":_vm.formBusy,"type":"button","variant":"primary"},on:{"click":_vm.submitForm}},[(_vm.formBusy)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Submit")])],1)],1)],1)],1)]}}])},[_c('b-row',[(_vm.companyList.length > 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company","label":"name","reduce":function (option) { return option.id; },"options":_vm.companyList},model:{value:(_vm.carrier.company_id),callback:function ($$v) {_vm.$set(_vm.carrier, "company_id", $$v)},expression:"carrier.company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2487783547)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Master transport document type","label-for":"transport_document_type"}},[_c('validation-provider',{attrs:{"name":"Master transport document type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"transport_document_type","label":"label","reduce":function (option) { return option.value; },"options":_vm.documentTypesList},model:{value:(_vm.carrier.transport_document_type),callback:function ($$v) {_vm.$set(_vm.carrier, "transport_document_type", $$v)},expression:"carrier.transport_document_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.carrier.name),callback:function ($$v) {_vm.$set(_vm.carrier, "name", $$v)},expression:"carrier.name"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('v-select',{attrs:{"id":"type","label":"value","options":['plane', 'truck']},model:{value:(_vm.carrier.type),callback:function ($$v) {_vm.$set(_vm.carrier, "type", $$v)},expression:"carrier.type"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required|max:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","name":"code","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.carrier.code),callback:function ($$v) {_vm.$set(_vm.carrier, "code", $$v)},expression:"carrier.code"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"EORI number","label-for":"eori_number"}},[_c('validation-provider',{attrs:{"name":"EORI number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"eori_number","name":"eori_number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.carrier.eori_number),callback:function ($$v) {_vm.$set(_vm.carrier, "eori_number", $$v)},expression:"carrier.eori_number"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","name":"address","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.carrier.address),callback:function ($$v) {_vm.$set(_vm.carrier, "address", $$v)},expression:"carrier.address"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }