<template>
  <validation-observer ref="carrierValidation">
    <b-modal
      :id="this.$attrs.id"
      title="Carrier details"
      size="l"
      scrollable
    >
      <b-row>
        <b-col
          v-if="companyList.length > 1"
          cols="12"
        >
          <b-form-group
            label="Company"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              name="Company"
              rules="required"
            >
              <v-select
                id="company"
                v-model="carrier.company_id"
                label="name"
                :reduce="option => option.id"
                :options="companyList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Master transport document type"
            label-for="transport_document_type"
          >
            <validation-provider
              #default="{ errors }"
              name="Master transport document type"
              rules="required"
            >
              <v-select
                id="transport_document_type"
                v-model="carrier.transport_document_type"
                label="label"
                :reduce="option => option.value"
                :options="documentTypesList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="carrier.name"
                name="name"
                :state="errors.length > 0 ? false:null"
                autocomplete="off"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Type"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="carrier.type"
              label="value"
              :options="['plane', 'truck']"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Code"
            label-for="code"
          >
            <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required|max:3"
            >
              <b-form-input
                id="code"
                v-model="carrier.code"
                name="code"
                :state="errors.length > 0 ? false:null"
                autocomplete="off"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="EORI number"
            label-for="eori_number"
          >
            <validation-provider
              #default="{ errors }"
              name="EORI number"
              rules="required"
            >
              <b-form-input
                id="eori_number"
                v-model="carrier.eori_number"
                name="eori_number"
                :state="errors.length > 0 ? false:null"
                autocomplete="off"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Address"
            label-for="address"
          >
            <validation-provider
              #default="{ errors }"
              name="Address"
              rules="required"
            >
              <b-form-input
                id="address"
                v-model="carrier.address"
                name="address"
                :state="errors.length > 0 ? false:null"
                autocomplete="off"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="text-right">
              <b-button
                :disabled="formBusy"
                variant="secondary"
                class="mr-2"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                :disabled="formBusy"
                type="button"
                variant="primary"
                @click="submitForm"
              >
                <b-spinner
                  v-if="formBusy"
                  small
                />
                <span v-else>Submit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BContainer,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BSpinner,
    BContainer,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: ['eventHub', 'carrier', 'companyList'],
  data() {
    return {
      formBusy: false,
      documentTypesList: [],
    }
  },
  created() {
    this.$http.get('/v1/shipment/document-types')
      .then(response => {
        this.documentTypesList = response.data.data
      })
  },
  methods: {
    submitForm() {
      this.$refs.carrierValidation.validate().then(success => {
        if (!success) {
          return
        }

        this.formBusy = true

        if (this.carrier.id) {
          this.$http.put(`/v1/companies/${this.carrier.company_id}/carriers/${this.carrier.id}`, this.carrier)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Carrier edited',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('carriers-modal')
              this.eventHub.$emit('refreshCarriers')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        } else {
          const companyId = this.carrier.company_id || this.$activeCompany().data.company.id
          this.$http.post(`/v1/companies/${companyId}/carriers`, this.carrier)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Carrier added',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('carriers-modal')
              this.eventHub.$emit('refreshCarriers')
            })
            .catch(error => {
              handleError(error, this.$toast)
            })
            .finally(() => {
              this.formBusy = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
